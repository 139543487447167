@import 'styles/layers.scss';

@layer uikit {
  .wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    right: 0;
    bottom: 0;
    background-color: rgba(187, 187, 187, 0.2);
    cursor: not-allowed;
  }
  
  .spinner {
    color: var(--blue3) !important;
  }
  
  .black {
    color: var(--black2) !important;
  }
  
  .small {
    width: 12px !important;
    height: 12px !important;
  }
  
  .medium {
    width: 14px !important;
    height: 14px !important;
  }
  
  .large {
    width: 2rem !important;
    height: 2rem !important;
  }
}