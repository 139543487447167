@import 'styles/layers.scss';
@layer component {
  .img {
    margin-right: 6px;
  }

  .btn {
    padding: 10px 18px;
    flex-direction: row;
    width: 100%;
    height: 40px;
  }

  .link {
    display: flex;
    align-items: center;
  }

  .label {
    line-height: 14px;
  }
}