@import 'styles/layers.scss';

@layer uikit {
  .root {
    color: var(--black7);
    position: relative;
    display: flex;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 9px 18px;
    border-radius: 4px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    &:focus {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  .link {
    text-decoration: none;
    color: inherit;
    &:hover, &:active {
      color: inherit;
    }
  }
  
  .base-link {
    display: inline-flex;
    background-color: transparent;
    text-decoration: underline;
    padding: 0;
    &.dark {
      color: var(--white);
    }
    &.light {
      color: var(--black);
    }
    &:hover:not(.disabled), &.active {
      color: var(--white);
    }
  }

  .blue-link {
    display: inline-flex;
    background-color: transparent;
    text-decoration: underline;
    padding: 0;
    &.dark {
      color: var(--blue2);
    }
  }

  .yellow-link {
    display: inline-flex;
    background-color: transparent;
    text-decoration: underline;
    padding: 0;
    &.dark {
      color: var(--yellow);
    }
    &:hover:not(.disabled), &.active {
      color: var(--yellow);
    }
  }
  
  .base {
    &.dark {
      background-color: var(--blue2);
    }
    &:hover:not(.disabled) {
      background-color: var(--blue1);
    }
    &.disabled:not(.processing) {
      background-color: var(--grey3);
    }
    &.processing {
      opacity: 0.6;
    }
  }
  
  .transparent {
    color: inherit;
    background-color: transparent;
    &:hover:not(.disabled) {
      background-color: var(--black3);
    }
  }
  
  .aqua {
    background-color: var(--aqua2);
    color: var(--white);
    &:hover:not(.disabled) {
      background-color: var(--grey1);
    }
    &.disabled {
      background-color: var(--aqua1);
    }
  }
  
  .obscure {
    color: var(--white);
    background-color: var(--black29);
  }
  
  .obscure2 {
    color: var(--white);
    background-color: var(--black3);
  }

  .black {
    color: var(--white);
    background-color: var(--black19);
    &:hover:not(.disabled) {
      background-color: var(--black2);
    }
    &.disabled {
      background-color: var(--black20);
    }
  }
  
  .black2 {
    color: var(--white);
    background-color: var(--black22);
    &:hover:not(.disabled) {
      background-color: var(--black15);
    }
    &.disabled {
      background-color: var(--black20);
    }
  }

  .black3 {
    color: var(--white);
    background-color: var(--black34);
    &:hover:not(.disabled) {
      background-color: var(--black35);
    }
    &.disabled {
      background-color: var(--black33);
    }
    &.active {
      background-color: var(--black34);
    }
  }

  .wide {
    padding: 10px 90px;
  }
  
  .loading-center {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  
  .loading {
    margin-right: 9px;
  }
  
  .border {
    border: 1px solid var(--black3);
  }
}